<template>
  <div class="basic-information">
    <Label
      class="basic-information--title"
      align="left"
      color="primary"
      uppercase
      size="small"
    >
      {{ $t("panels.retake.basicInformationForm.title") }} <br />
    </Label>
    <Label
      class="basic-information--description"
      align="left"
      color="tertiary"
      size="small"
    >
      {{ $t("panels.retake.basicInformationForm.subtitle") }}
    </Label>
    <form id="retakestep1" name="retakestep1">
      <div class="mt-4">
        <CpLicensePlate
          ref="licenseplate"
          :value="getPlate"
          :label="$t('labels.licenseplate')"
          @onChange="handleLicensePlateChange"
          @onValid="handleValid"
        />
      </div>
      <div v-if="validLicensePlate(getPlate)">
        <div class="mt-4">
          <Select
            ref="brand"
            @onChange="
              value => {
                this.$store.commit('setBrandById', value);
                fetchModels();
              }
            "
            id="brand-select"
            :options="getBrands"
            optionsBgColor="#F5F7F9"
            :border="false"
            :clearable="true"
            @onClear="clearBrand"
          />
        </div>
        <div class="mt-4" v-if="renderComponent">
          <CpDatePicker
            ref="platedate"
            :value="getPlateDate"
            @onChange="handleBirthday"
          />
        </div>
      </div>
      <div v-if="showRest">
        <div class="mt-4">
          <Select
            ref="model"
            @onChange="handleChangeModel"
            id="model-select"
            :options="getModels"
            optionsBgColor="#F5F7F9"
            :border="false"
            :clearable="true"
            @onClear="clearModel"
          ></Select>
        </div>
        <div class="mt-4" v-if="renderComponent">
          <CpInput
            ref="kilometers"
            :label="$t('labels.kilometers')"
            :value="getKilometersRetake"
            :validator="validateKilometers"
          />
        </div>
      </div>
      <div v-if="showRest">
        <div class="mt-4">
          <Select
            ref="version"
            @onChange="
              value => {
                if (!this.isPrefill) {
                  this.$store.commit('setBcaId', value);
                  value = getVersions.filter(
                    version => version.value == value
                  )[0].text;
                }
                this.$store.commit('setVersion', value);
                this.$store.commit('setFuelTransmission', value);
              }
            "
            :key="getVersions.length"
            id="fuel-select"
            :options="getVersions"
            optionsBgColor="#F5F7F9"
            :border="false"
            :clearable="true"
            @onClear="clearVersion"
          ></Select>
        </div>
      </div>
    </form>
    <FormStep :step="1" :validate="validate" />
  </div>
</template>

<script>
import { FormStep } from "../../../molecules";
import { Label, Select } from "../../../atoms";
import { mapGetters } from "vuex";
// import dayjs from "dayjs";
import { CpLicensePlate, CpDatePicker, CpInput } from "../../..";
import LABEL_TYPE from "../../../../utils/labelType";
import formValidator from "../../../../utils/formValidator";

import { FOOTPRINT_TYPES, registerEvent } from "../../../../api/footprint";

export default {
  name: "BasicInformationForm",
  data() {
    return {
      errorLicensePlate: false,
      requestedDetails: false,
      errs: {},
      renderComponent: true
    };
  },
  components: {
    FormStep,
    Label,
    Select,
    CpLicensePlate,
    CpDatePicker,
    CpInput
  },
  methods: {
    handleValid() {
      this.getVehicleByPlate();
    },
    handleChangeModel(value) {
      this.$store.dispatch("storeModelAndVersions", value);
      this.$store.commit("setVersion", "Versões");
    },
    validateKilometers(value) {
      this.$store.commit("setKilometersRetake", value);
      const n = parseInt(value, 10);
      if (!n) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.kilometers.number")];
      }
      if (n < 100) {
        return [LABEL_TYPE.ERROR, this.$t("labels.errors.kilometers.long")];
      }
      this.$store.commit("setKilometersRetake", n);
      return [LABEL_TYPE.SUCCESS, ""];
    },
    handleBirthday(value) {
      this.$store.commit("setPlateDate", value);
      this.fetchModels();
    },
    handleLicensePlateChange([val]) {
      this.$store.commit("setPlate", val);
      if (val.length === 10) {
        this.getVehicleByPlate();
      }
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    validate() {
      const refs = [
        {
          ref: "platedate",
          nested: true,
          value: this.getPlateDate
        },
        {
          ref: "kilometers",
          value: this.getKilometersRetake
        },
        {
          ref: "brand",
          value: !!this.getBrand
        },
        {
          ref: "model",
          value: this.getModell && this.getModell !== -1
        },
        {
          ref: "version",
          value: this.getVrsn !== "Versões"
        }
      ];

      const errs = formValidator(refs, this.$refs);
      if (errs.length > 0) {
        return false;
      }

      const [day, month, year] = this.getPlateDate.split("/");

      this.$store.commit("setValuationRequest", {
        plate: this.getPlate.replaceAll("-", ""),
        brand: this.getBrand.text,
        model: this.getModell,
        version: this.getVrsn,
        kilometers: this.getKilometersRetake,
        plateDate: `${year}-${month}-${day}`,
        bcaId: this.getBcaId,
        fuel: this.getTradeinFuel,
        transmission: this.getTradeinTransmission,
        assignToUser: true
      });

      const footprintEvent = {
        plate: this.getPlate.replaceAll("-", ""),
        brand: this.getBrand.text,
        model: this.getModell,
        version: this.getVrsn,
        kilometers: this.getKilometersRetake,
        plateDate: `${year}-${month}-${day}`
      };
      registerEvent(FOOTPRINT_TYPES.RETAKE_STEP_1, footprintEvent);

      return true;
    },
    validLicensePlate: function(licensePlate) {
      const re = /^[a-zA-Z0-9]{6}$/;
      return re.test(licensePlate);
    },
    async getVehicleByPlate() {
      if (this.validLicensePlate(this.getPlate)) {
        this.requestedDetails = true;
        await this.$store.dispatch("storeVehicleByPlate");
        this.forceRerender();
      }
    },
    async fetchModels() {
      if (this.getBrand && this.getPlateDate && !this.isPrefill) {
        await this.$store.dispatch("storeModels");
      }
    },
    clearBrand() {
      this.$store.commit("setBrand", "");
      this.clearModel();
    },
    clearModel() {
      this.$store.commit("setModel", "");
      this.clearVersion();
    },
    clearVersion() {
      this.$store.commit("setVersion", "");
    }
  },
  computed: {
    ...mapGetters([
      "getPlate",
      "getBrand",
      "getBrands",
      "getPlateDate",
      "getModell",
      "getModels",
      "getVrsn",
      "getVersions",
      "getKilometersRetake",
      "getBcaId",
      "isPrefill",
      "getTradeinFuel",
      "getTradeinTransmission"
    ]),
    showRest() {
      return (
        (this.getBrand &&
          this.getBrand?.value !== -1 &&
          this.getPlateDate?.length === 10) ||
        this.isPrefill
      );
    }
  }
};
</script>
<style scoped>
.basic-information--title {
  font-size: 16px !important;
}

.basic-information--description {
  font-size: 14px !important;
}

:deep(.selectCustom-options) {
  max-height: 200px;
  @apply overflow-y-auto;
  @apply z-50;
}

:deep(.selectNative) {
  -webkit-appearance: menulist-button;
  -khtml-appearance: menulist-button;
  -moz-appearance: menulist-button;
  -ms-appearance: menulist-button;
  appearance: menulist-button;
  border: none;
  background-color: #f5f7f9;
}

:deep(.indicator) {
  color: #e63312;
  margin: 7px;
  margin-left: 16px;
  font-size: 14px !important;
  line-height: 14px !important;
}
</style>
