var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basic-information"},[_c('Label',{staticClass:"basic-information--title",attrs:{"align":"left","color":"primary","uppercase":"","size":"small"}},[_vm._v(" "+_vm._s(_vm.$t("panels.retake.basicInformationForm.title"))+" "),_c('br')]),_c('Label',{staticClass:"basic-information--description",attrs:{"align":"left","color":"tertiary","size":"small"}},[_vm._v(" "+_vm._s(_vm.$t("panels.retake.basicInformationForm.subtitle"))+" ")]),_c('form',{attrs:{"id":"retakestep1","name":"retakestep1"}},[_c('div',{staticClass:"mt-4"},[_c('CpLicensePlate',{ref:"licenseplate",attrs:{"value":_vm.getPlate,"label":_vm.$t('labels.licenseplate')},on:{"onChange":_vm.handleLicensePlateChange,"onValid":_vm.handleValid}})],1),(_vm.validLicensePlate(_vm.getPlate))?_c('div',[_c('div',{staticClass:"mt-4"},[_c('Select',{ref:"brand",attrs:{"id":"brand-select","options":_vm.getBrands,"optionsBgColor":"#F5F7F9","border":false,"clearable":true},on:{"onChange":value => {
              this.$store.commit('setBrandById', value);
              _vm.fetchModels();
            },"onClear":_vm.clearBrand}})],1),(_vm.renderComponent)?_c('div',{staticClass:"mt-4"},[_c('CpDatePicker',{ref:"platedate",attrs:{"value":_vm.getPlateDate},on:{"onChange":_vm.handleBirthday}})],1):_vm._e()]):_vm._e(),(_vm.showRest)?_c('div',[_c('div',{staticClass:"mt-4"},[_c('Select',{ref:"model",attrs:{"id":"model-select","options":_vm.getModels,"optionsBgColor":"#F5F7F9","border":false,"clearable":true},on:{"onChange":_vm.handleChangeModel,"onClear":_vm.clearModel}})],1),(_vm.renderComponent)?_c('div',{staticClass:"mt-4"},[_c('CpInput',{ref:"kilometers",attrs:{"label":_vm.$t('labels.kilometers'),"value":_vm.getKilometersRetake,"validator":_vm.validateKilometers}})],1):_vm._e()]):_vm._e(),(_vm.showRest)?_c('div',[_c('div',{staticClass:"mt-4"},[_c('Select',{key:_vm.getVersions.length,ref:"version",attrs:{"id":"fuel-select","options":_vm.getVersions,"optionsBgColor":"#F5F7F9","border":false,"clearable":true},on:{"onChange":value => {
              if (!this.isPrefill) {
                this.$store.commit('setBcaId', value);
                value = _vm.getVersions.filter(
                  version => version.value == value
                )[0].text;
              }
              this.$store.commit('setVersion', value);
              this.$store.commit('setFuelTransmission', value);
            },"onClear":_vm.clearVersion}})],1)]):_vm._e()]),_c('FormStep',{attrs:{"step":1,"validate":_vm.validate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }