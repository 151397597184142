<template>
  <div class="content-center">
    <Label
      class="text-title"
      align="left"
      color="primary"
      uppercase
      size="small"
    >
      {{ $t("panels.retake.realValueForm.title") }} <br />
    </Label>
    <Label class="text-title" align="left" color="tertiary" size="x-small">
      {{ $t("panels.retake.realValueForm.subtitle") }}
    </Label>
    <div class="grid justify-items-center mt-4">
      <img
        :src="
          $t('resources.images.retakepage.vehicleValue') ||
            require('../../../../assets/images/car-coin.svg')
        "
      />
      <Label class="text-title-value" align="center" size="small">
        {{ $t("panels.retake.realValueForm.vehicleValueLabel") }}
      </Label>
      <Label class="text-title-value" align="center" size="small">
        {{ formatPlate }}
      </Label>
      <Label
        class="text-title-money-value"
        :styles="{ 'font-weight': '600' }"
        align="center"
        color="secondary"
        size="medium"
        bold="bold"
      >
        {{ getValuation }}
      </Label>
    </div>
    <ValuationAdvantages class="valuation-advantages"></ValuationAdvantages>
    <FormStep :step="3" :validate="validate" />
  </div>
</template>

<script>
import { FormStep } from "../../../molecules";
import { Label } from "../../../atoms";
import { ValuationAdvantages } from "../../../organisms";
import { mapGetters } from "vuex";
export default {
  name: "RealValueForm",
  data() {
    return {};
  },
  components: {
    FormStep,
    Label,
    ValuationAdvantages
  },
  methods: {
    validate() {
      return true;
    }
  },
  computed: {
    ...mapGetters(["getValuation", "getPlate"]),
    formatPlate() {
      return this.getPlate.match(/.{1,2}/g).join("-");
    }
  }
};
</script>

<style scoped>
.text-title {
  font-size: 16px !important;
}

.text-title-value {
  font-size: 21px !important;
}

.text-title-money-value {
  font-size: 32px !important;
}

.valuation-advantages {
  margin-top: 32px;
}
</style>
