<template>
  <div>
    <Page>
      <template v-slot:content>
        <Section
          id="id1"
          class="section-header"
          :style="heroImage"
          bgColor="#F5F7F9"
        >
          <div
            class="
              w-full
              h-auto
              py-6
              md:pl-28
              md:mt-52
              md:mb-20
              text-center
              md:text-left
            "
          >
            <Heading
              class="retake--title"
              :label="$t('pages.retake.title')"
              tag="h1"
              weight="bold"
            />
            <Label
              class="retake--subtitle"
              color="tertiary"
              size="small"
              bold="normal"
              align="left"
            >
              {{ $t("pages.retake.subtitle") }}
            </Label>
          </div>
          <div
            class="
              w-full
              md:py-10
              md:pr-28
              grid
              md:justify-items-end
              h-2/3
              md:mt-32
            "
          >
            <Section id="id2" bgColor="white" class="md:w-2/3">
              <div
                class="p-4"
                style="
                  display: block;
                  justify-content: center;
                  text-align: left;
                "
              >
                <Label class="plate--title" size="small">
                  {{ $t("pages.retake.evaluationTitles[1]") }}
                </Label>
                <CpLicensePlate
                  :label="$t('pages.retake.evaluationTitles.placeholder')"
                  @onChange="handleChange"
                  :value="getPlate"
                />
                <Button
                  @onClick="openEvaluation"
                  type="primary"
                  :disabled="!isValid"
                  uppercase
                  fill
                  :label="$t('pages.retake.howItWorks.openEvaluationBtn')"
                  class="start-evaluation"
                />
              </div>
            </Section>
          </div>
        </Section>
        <Section id="details" class="mt-20 details--section justify-center">
          <div class="grid justify-items-center details-container">
            <SectionHeader
              :title="$t('pages.retake.howItWorks.title')"
              :subtitle="$t('pages.retake.howItWorks.subtitle')"
              type="primary"
              titleSize="medium"
              subtitleSize="small"
            ></SectionHeader>
            <Section id="details-row" class="w-3/4 how-it-works">
              <div
                class="
                  my-5
                  grid
                  justify-items-center
                  space-y-5
                  md:mr-10
                  md:w-96
                "
              >
                <img
                  width="106px"
                  height="106px"
                  :src="
                    $t('resources.images.retakepage.card1') ||
                      require('./../../../assets/images/evaluate.svg')
                  "
                />
                <Heading
                  class="how-it-works--title"
                  :label="$t('pages.retake.howItWorks.steps.step1.title')"
                  tag="h3"
                />
                <Heading
                  class="how-it-works--subtitle"
                  :label="$t('pages.retake.howItWorks.steps.step1.subtitle')"
                  tag="h4"
                />
              </div>
              <div class="my-5 grid justify-items-center space-y-5 md:w-96">
                <img
                  width="106px"
                  height="106px"
                  :src="
                    $t('resources.images.retakepage.card2') ||
                      require('./../../../assets/images/trade.svg')
                  "
                />
                <Heading
                  class="how-it-works--title"
                  :label="$t('pages.retake.howItWorks.steps.step2.title')"
                  tag="h3"
                />
                <Heading
                  class="how-it-works--subtitle"
                  :label="$t('pages.retake.howItWorks.steps.step2.subtitle')"
                  tag="h4"
                />
              </div>
              <div
                class="
                  my-5
                  grid
                  justify-items-center
                  space-y-5
                  md:ml-10
                  md:w-96
                "
              >
                <img
                  width="106px"
                  height="106px"
                  :src="
                    $t('resources.images.retakepage.card3') ||
                      require('./../../../assets/images/coin.svg')
                  "
                />
                <Heading
                  class="how-it-works--title"
                  :label="$t('pages.retake.howItWorks.steps.step3.title')"
                  tag="h3"
                />
                <Heading
                  class="how-it-works--subtitle"
                  :label="$t('pages.retake.howItWorks.steps.step3.subtitle')"
                  tag="h4"
                />
              </div>
            </Section>
          </div>
        </Section>
        <Section>
          <div class="mx-auto my-5 md:mb-20">
            <Button
              class="start-evaluation"
              @onClick="openEvaluation"
              type="primary"
              :label="$t('pages.retake.howItWorks.openEvaluationBtn')"
              uppercase
              fill
            />
          </div>
        </Section>
        <div class="faqs--container">
          <Section id="sub-header" class="sub-header" bgColor="white">
            <div class="header">
              <Label
                class="sub-header-title"
                color="primary"
                align="center"
                size="medium"
                bold="bold"
                >{{ $t("pages.howitworks.sections.subHeaders[4].title") }}<br
              /></Label>
              <Label
                class="sub-header-subtitle"
                align="center"
                size="medium"
                color="tertiary"
                >{{ $t("pages.howitworks.sections.subHeaders[4].subtitle")
                }}<br
              /></Label>
            </div>
          </Section>

          <FaqsHome
            retake
            class="faqs-home"
            :accordionList="[
              {
                title: $t('pages.retake.howItWorks.faqs[1].title'),
                content: $t('pages.retake.howItWorks.faqs[1].content')
              },
              {
                title: $t('pages.retake.howItWorks.faqs[2].title'),
                content: $t('pages.retake.howItWorks.faqs[2].content')
              },
              {
                title: $t('pages.retake.howItWorks.faqs[3].title'),
                content: $t('pages.retake.howItWorks.faqs[3].content')
              }
            ]"
            @onActiveFootprint="e => faqsFootprint(e)"
          />
        </div>
      </template>
    </Page>
    <RetakePanel @onResetPlate="resetPlate" />
  </div>
</template>

<script>
import { Page } from "./../../templates";
import { SectionHeader } from "../../molecules";
import { FaqsHome } from "../../organisms";
import { Button, Label, Section } from "../../atoms";
import { RetakePanel } from "../../panels";
import { mapGetters } from "vuex";
import CpLicensePlate from "../../refactor/CpLicensePlate.vue";
import { Heading } from "@sc/ds-ui-component-library";

import { PAGES } from "@/enums/pages";

import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

export default {
  components: {
    Button,
    Label,
    Page,
    RetakePanel,
    FaqsHome,
    Section,
    SectionHeader,
    CpLicensePlate,
    Heading
  },
  data() {
    return {
      isValid: false,
      id: this.$route.params.id,
      plateNumber: "",
      errorLicensePlate: false,
      showInfoMessage: false,
      rerender: false
    };
  },
  metaInfo() {
    return {
      title: this.$t("meta.tradeIn.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.tradeIn.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.tradeIn.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.tradeIn.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.tradeIn.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.tradeIn.image")
        }
      ]
    };
  },
  methods: {
    handleChange([val, isValid]) {
      this.$store.commit("setPlate", val);
      this.isValid = isValid;
    },
    resetPlate() {
      this.rerender = true;
      this.rerender = false;
    },
    async openEvaluation() {
      this.$store.state.isOpenRetakePanel = true;
      if (this.getPlate != "") {
        await this.$store.dispatch("storeVehicleByPlate");
      }
    },
    onFocusHandler() {
      this.showInfoMessage = true;
    },
    faqsFootprint(e) {
      const footprintEvent = {
        from: PAGES.RETAKE,
        title: e?.title
      };

      registerEvent(FOOTPRINT_TYPES.FAQS, footprintEvent);
    }
  },
  computed: {
    ...mapGetters(["getPlate"]),
    plateValue() {
      return this.rerender ? "" : this.getPlate;
    },
    heroImage() {
      return {
        backgroundImage: `url(${this.$t("resources.images.retakepage.hero") ||
          require("../../../assets/images/Clicktobuy_Retoma.webp")})`
      };
    }
  }
};
</script>

<style lang="css" scoped src="./RetakeEvaluationPage.css"></style>
