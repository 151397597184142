<template>
  <div class="retake-panel--container">
    <MultiStepForm
      class="px-10"
      :numberSteps="numberSteps"
      :s="step"
      @onChange="stepAction"
      @onSubmit="submit"
      :nextLabel="setNextLabel()"
      :prevLabel="prevLabel"
      :submitLabel="submitLabel"
    >
      <BasicInformationForm v-if="step === 1" />
      <PersonalFormStep
        hideBirthdate
        showConcession
        :reservationPanel="false"
        v-if="step === 2"
        @onSubmit="submit"
      />
      <!-- <RealValueForm v-if="step === 3" /> -->
    </MultiStepForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { MultiStepForm } from "../../organisms";
import { BasicInformationForm } from "./steps";
import PersonalFormStep from "../../sidebars/reserve/steps/PersonalFormStep.vue";

export default {
  name: "RetakeMultiForm",
  data() {
    return {
      step: 1,
      numberSteps: 2,
      nextLabel: this.$t("panels.retake.nextLabel"),
      prevLabel: this.$t("panels.retake.prevLabel"),
      submitLabel: this.$t("panels.retake.obtainEvaluation")
    };
  },
  components: {
    BasicInformationForm,
    MultiStepForm,
    PersonalFormStep
  },
  methods: {
    submit() {
      this.$emit("onSubmit");
    },
    setNextLabel() {
      if (this.step == 2) {
        this.nextLabel = this.$t("panels.retake.obtainEvaluation");
      } else {
        this.nextLabel = this.$t("panels.retake.nextLabel");
      }
      return this.nextLabel;
    },
    stepAction(s) {
      this.step = s;
    }
  },
  computed: {
    ...mapGetters(["getToken", "isGuest"])
  }
};
</script>

<style scoped>
:deep(.panel--content) {
  overflow: auto;
}
</style>
