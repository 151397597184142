var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block--container"},[(_vm.isGuest)?_c('div',[_c('Label',{staticClass:"text-title",attrs:{"align":"left","color":"primary","size":"small"}},[_vm._v(" Veja a sua proposta de imediato! Registe-se ou faça "),_c('span',{staticClass:"retake-link",on:{"click":_vm.onClickLogin}},[_vm._v(" Login ")]),_vm._v(" para concluir a proposta e receber a sua avaliação. ")]),_c('div',{staticClass:"mt-4"},[_c('InputForm',{staticClass:"mt-5",attrs:{"type":"primary","inputType":"text","label":"Email *","bgColor":"#F5F7F9","placeholder":"Email","inputValue":_vm.registerForm.email},on:{"onChange":value => {
            _vm.registerForm.email = value;
          }}}),(_vm.errs.email)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errs.email))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('InputForm',{staticClass:"mt-5",attrs:{"type":"primary","inputType":"text","label":"Telemóvel *","bgColor":"#F5F7F9","placeholder":"Telemóvel","inputValue":_vm.registerForm.phone},on:{"onChange":value => {
            _vm.registerForm.phone = value;
          }}}),(_vm.errs.phone)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errs.phone))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('InputForm',{staticClass:"mt-5",attrs:{"type":"primary","inputType":"password","label":"Password *","bgColor":"#F5F7F9","placeholder":"Password","inputValue":_vm.registerForm.password},on:{"onFocus":_vm.onFocusHandler,"onBlur":_vm.onBlurHandler,"onChange":value => {
            _vm.registerForm.password = value;
          }}}),(_vm.errs.password && _vm.showInfoMessage)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errs.password))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('Label',{staticClass:"text-title",attrs:{"align":"left","color":"primary","size":"x-small"}},[_vm._v(" Ao criares conta, estás a concordar com os "),_c('span',{staticClass:"retake-link",on:{"click":_vm.onClickUtilizationTerms}},[_vm._v(" Termos de Utilização ")]),_vm._v(" e "),_c('span',{staticClass:"retake-link",on:{"click":_vm.onClickPrivacyPolicy}},[_vm._v(" Política de privacidade ")]),_vm._v(" do Digital Store. ")])],1)],1):_c('div',[_c('Label',{staticClass:"text-title",attrs:{"align":"left","color":"primary","size":"x-small"}},[_vm._v(" Confirma os teus dados: ")]),_c('div',{staticClass:"mt-4"},[_c('InputForm',{key:_vm.getUserName && _vm.getUserName.length,staticClass:"mt-5",attrs:{"type":"primary","inputType":"text","label":"Nome de utilizador","bgColor":"#F5F7F9","placeholder":"Nome de utilizador","inputValue":_vm.getUserName},on:{"onChange":value => {
            _vm.registerForm.name = value;
          }}}),(_vm.errs.name)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errs.name))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('InputForm',{key:_vm.getUserEmail && _vm.getUserEmail.length,staticClass:"mt-5",attrs:{"type":"primary","inputType":"text","label":"Email","bgColor":"#F5F7F9","placeholder":"Email","inputValue":_vm.getUserEmail},on:{"onChange":value => {
            _vm.registerForm.email = value;
          }}}),(_vm.errs.email)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errs.email))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('InputForm',{key:_vm.getUserPhoneNumber && _vm.getUserPhoneNumber.length,staticClass:"mt-5",attrs:{"type":"primary","inputType":"text","label":"Telemóvel","bgColor":"#F5F7F9","placeholder":"Telemóvel","inputValue":_vm.getUserPhoneNumber},on:{"onChange":value => {
            _vm.registerForm.phone = value;
          }}}),(_vm.errs.phone)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errs.phone))]):_vm._e()],1),_c('div',{staticClass:"mt-4"},[(false)?_c('InputForm',{staticClass:"mt-5",attrs:{"isDisabled":true,"type":"primary","inputType":"text","label":"Morada","bgColor":"#F5F7F9","placeholder":"Morada","inputValue":_vm.getUserAddress},on:{"onChange":value => {
            _vm.registerForm.address = value;
          }}}):_vm._e(),(_vm.errs.address)?_c('Label',{attrs:{"color":"tertiary","bold":"thin","size":"small"}},[_vm._v(" "+_vm._s(_vm.errs.address))]):_vm._e()],1)],1),_c('FormStep',{attrs:{"step":2,"validate":_vm.validate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }