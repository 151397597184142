<template>
  <Panel :open="isOpenRetakePanel" @onClose="closeRetakePanel">
    <template slot="header">
      <Label size="medium" color="primary" bold="bold">{{
        $t("panels.retake.title")
      }}</Label>
    </template>
    <template slot="content">
      <RetakeMultiForm v-if="!success" @onSubmit="showSuccess" />
      <RetakeSuccess v-if="success" />
    </template>
  </Panel>
</template>

<script>
import { mapState } from "vuex";

import RetakeMultiForm from "./RetakeMultiForm.vue";
import RetakeSuccess from "./RetakeSuccess.vue";
import { Label } from "../../atoms";
import { Panel } from "../../molecules";
export default {
  name: "RetakePanel",
  data() {
    return {
      success: false
    };
  },
  components: {
    RetakeMultiForm,
    RetakeSuccess,
    Panel,
    Label
  },
  mounted() {
    this.$store.dispatch("storeDealers");
  },
  methods: {
    showSuccess() {
      this.success = true;
      this.$store.commit("spinnerModule/setLoading", false);
    },
    closeRetakePanel() {
      this.$store.commit("resetRetake");
      this.$emit("onResetPlate");
      this.$store.state.isOpenRetakePanel = false;
      this.success = false;
    }
  },
  computed: {
    ...mapState(["isOpenRetakePanel"])
  }
};
</script>
<style scoped>
:deep(.panel--header) {
  margin: 1rem;
  margin-left: 2rem;
}

:deep(.floating) {
  @apply text-tertiary !important;
}
</style>
