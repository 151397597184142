<template>
  <div class="block--container">
    <div v-if="isGuest">
      <Label class="text-title" align="left" color="primary" size="small">
        Veja a sua proposta de imediato! Registe-se ou faça
        <span @click="onClickLogin" class="retake-link"> Login </span> para
        concluir a proposta e receber a sua avaliação.
      </Label>
      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="text"
          label="Email *"
          bgColor="#F5F7F9"
          @onChange="
            value => {
              registerForm.email = value;
            }
          "
          class="mt-5"
          placeholder="Email"
          :inputValue="registerForm.email"
        />
        <Label v-if="errs.email" color="tertiary" bold="thin" size="small">
          {{ errs.email }}</Label
        >
      </div>
      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="text"
          label="Telemóvel *"
          bgColor="#F5F7F9"
          @onChange="
            value => {
              registerForm.phone = value;
            }
          "
          class="mt-5"
          placeholder="Telemóvel"
          :inputValue="registerForm.phone"
        />
        <Label v-if="errs.phone" color="tertiary" bold="thin" size="small">
          {{ errs.phone }}</Label
        >
      </div>
      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="password"
          label="Password *"
          bgColor="#F5F7F9"
          @onFocus="onFocusHandler"
          @onBlur="onBlurHandler"
          @onChange="
            value => {
              registerForm.password = value;
            }
          "
          class="mt-5"
          placeholder="Password"
          :inputValue="registerForm.password"
        />
        <Label
          v-if="errs.password && showInfoMessage"
          color="tertiary"
          bold="thin"
          size="small"
        >
          {{ errs.password }}</Label
        >
      </div>
      <div class="mt-4">
        <Label class="text-title" align="left" color="primary" size="x-small">
          Ao criares conta, estás a concordar com os
          <span @click="onClickUtilizationTerms" class="retake-link">
            Termos de Utilização
          </span>
          e
          <span @click="onClickPrivacyPolicy" class="retake-link">
            Política de privacidade
          </span>
          do Digital Store.
        </Label>
      </div>
    </div>
    <div v-else>
      <Label class="text-title" align="left" color="primary" size="x-small">
        Confirma os teus dados:
      </Label>
      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="text"
          label="Nome de utilizador"
          bgColor="#F5F7F9"
          class="mt-5"
          placeholder="Nome de utilizador"
          :inputValue="getUserName"
          :key="getUserName && getUserName.length"
          @onChange="
            value => {
              registerForm.name = value;
            }
          "
        />
        <Label v-if="errs.name" color="tertiary" bold="thin" size="small">
          {{ errs.name }}</Label
        >
      </div>
      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="text"
          label="Email"
          bgColor="#F5F7F9"
          class="mt-5"
          placeholder="Email"
          :inputValue="getUserEmail"
          :key="getUserEmail && getUserEmail.length"
          @onChange="
            value => {
              registerForm.email = value;
            }
          "
        />
        <Label v-if="errs.email" color="tertiary" bold="thin" size="small">
          {{ errs.email }}</Label
        >
      </div>
      <div class="mt-4">
        <InputForm
          type="primary"
          inputType="text"
          label="Telemóvel"
          bgColor="#F5F7F9"
          class="mt-5"
          placeholder="Telemóvel"
          :inputValue="getUserPhoneNumber"
          :key="getUserPhoneNumber && getUserPhoneNumber.length"
          @onChange="
            value => {
              registerForm.phone = value;
            }
          "
        />
        <Label v-if="errs.phone" color="tertiary" bold="thin" size="small">
          {{ errs.phone }}</Label
        >
      </div>
      <div class="mt-4">
        <InputForm
          v-if="false"
          :isDisabled="true"
          type="primary"
          inputType="text"
          label="Morada"
          bgColor="#F5F7F9"
          class="mt-5"
          placeholder="Morada"
          :inputValue="getUserAddress"
          @onChange="
            value => {
              registerForm.address = value;
            }
          "
        />
        <Label v-if="errs.address" color="tertiary" bold="thin" size="small">
          {{ errs.address }}</Label
        >
      </div>
    </div>
    <FormStep :step="2" :validate="validate" />
  </div>
</template>

<script>
import { FormStep, InputForm } from "../../../molecules";
import { Label } from "../../../atoms";
import { mapGetters } from "vuex";
import v from "../../../../utils/validator.js";
import { EventBus } from "../../../../utils/eventbus.js";
export default {
  name: "RegisterForm",
  data() {
    return {
      renderComponent: false,
      registerForm: {
        name: this.getUserName,
        email: "",
        phone: "",
        password: "",
        address: ""
      },
      errs: {
        password: ""
      },
      termsOfUseURL: "/termos-e-condicoes",
      privacyPolicyURL: "/politica-de-privacidade",
      showInfoMessage: false
    };
  },
  components: {
    FormStep,
    InputForm,
    Label
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    validate() {
      const { email, name, phone, password } = this.registerForm;
      const form = {
        email: email ? email : this.getUserEmail,
        name: name ? name : this.getUserName,
        phone: phone ? phone : this.getUserPhoneNumber,
        password
      };
      this.errs = v(
        this.isGuest ? "retakeRegisterForm" : "retakeLoggedIn",
        form
      );

      if (Object.values(this.errs).some(Boolean)) {
        return false;
      }

      if (this.isGuest) {
        this.triggerRegister({
          email: this.registerForm.email,
          name: this.registerForm.name,
          phoneNumber: this.registerForm.phone,
          password: this.registerForm.password
        });
        return false;
      }

      if (!this.guest) {
        this.requestValuation();
      }
      return false;
    },
    validatePassword() {
      this.errs = v("passOnly", this.registerForm);
    },
    async triggerRegister(payload) {
      const success = await this.$store.dispatch("storeRegisterToken", payload);
      if (success && this.getUserStatus === 1) this.requestValuation();
    },
    onClickPrivacyPolicy() {
      window.open(this.privacyPolicyURL, "_blank");
    },
    onClickLogin() {
      if (this.isGuest) {
        this.forceRerender();
        return (this.$store.state.isOpenSigninPopup = true);
      }
    },
    onClickUtilizationTerms() {
      window.open(this.termsOfUseURL, "_blank");
    },
    requestValuation() {
      const userInfo = {
        name: this.getUserName,
        email: this.getUserEmail,
        phoneNumber: this.getUserPhoneNumber
      };
      this.$store
        .dispatch("storeValuation", userInfo)
        .then(result => {
          console.log(result);
          EventBus.$emit("valid", 2);
        })
        .catch(error => {
          console.log(error);
        });
    },
    onFocusHandler() {
      this.validatePassword();
      this.showInfoMessage = true;
    },
    onBlurHandler() {
      this.showInfoMessage = false;
    }
  },
  computed: {
    ...mapGetters([
      "isGuest",
      "getValuationRequest",
      "getValuation",
      "getUserName",
      "getUserEmail",
      "getUserPhoneNumber",
      "getUserAddress",
      "getUserStatus"
    ])
  }
};
</script>
<style scoped lang="css">
.text-title {
  font-size: 16px !important;
}

.retake-link {
  color: #00aad2;
  text-decoration: underline;
  @apply cursor-pointer;
}
</style>
