<template>
  <div class="content-center">
    <div class="p-9">
      <Label
        class="text-title"
        align="left"
        color="primary"
        uppercase
        size="small"
      >
        {{ $t("panels.retake.realValueForm.title") }} <br />
      </Label>
      <Label class="text-title" align="left" color="tertiary" size="x-small">
        {{ $t("panels.retake.realValueForm.subtitle") }}
      </Label>
      <div class="grid justify-items-center mt-4">
        <img
          :src="
            $t('resources.images.retakepage.vehicleValue') ||
              require('../../../assets/images/car-coin.svg')
          "
        />
        <Label class="text-title-value" align="center" size="small">
          {{ $t("panels.retake.realValueForm.vehicleValueLabel") }}
        </Label>
        <Label class="text-title-value" align="center" size="small">
          {{ formatPlate }}
        </Label>
        <Label
          class="text-title-money-value"
          :styles="{ 'font-weight': '600' }"
          align="center"
          color="secondary"
          size="medium"
          bold="bold"
        >
          {{ getRetakeValuationLabel }}
        </Label>
      </div>

      <ValuationAdvantages class="valuation-advantages"></ValuationAdvantages>
    </div>
    <div
      class="h-full grid justify-items-center px-5 md:px-36 space-y-5 content-center "
    >
      <Label class="text-subtitle" align="center" color="tertiary" size="small">
        {{ $t("pages.retake.retakeSuccess.subtitle[1]") }}
        <span @click="onClickRetake" class="retake-link">
          {{ $t("pages.retake.retakeSuccess.subtitleSpan") }}
        </span>
        {{ $t("pages.retake.retakeSuccess.subtitle[2]") }}
      </Label>
      <Button
        class="search-button"
        @onClick="onClickSearch"
        type="primary"
        :label="$t('pages.retake.retakeSuccess.SearchbtnLabel')"
        uppercase
        fill
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label, Button } from "../../atoms";
import { ValuationAdvantages } from "../../organisms";
import { PAGES } from "@/enums/pages";

export default {
  name: "RetakeSuccess",
  data() {
    return {};
  },
  components: {
    Button,
    Label,
    ValuationAdvantages
  },
  methods: {
    onClickSearch() {
      this.$router.push({ name: PAGES.SEARCH });
    },
    onClickRetake() {
      this.$router.push({ name: PAGES.RETAKES });
    },
    validate() {
      return true;
    }
  },
  computed: {
    ...mapGetters(["getValuation", "getPlate"]),
    formatPlate() {
      return this.getPlate.match(/.{1,2}/g).join("-");
    },
    getRetakeValuationLabel() {
      return this.getValuation === 0 || this.getValuation === "0"
        ? this.$t("pages.retake.emptyValue")
        : this.getValuation;
    }
  }
};
</script>

<style scoped>
.text-title {
  font-size: 16px;
}

.text-title-value {
  font-size: 21px;
}

.text-title-money-value {
  font-size: 32px;
}

.valuation-advantages {
  margin-top: 32px;
}
.retake-link {
  @apply text-secondary-500;
  @apply underline;
  @apply cursor-pointer;
}

.search-button {
  font-size: 14px !important;
  width: 241px !important;
  height: 56px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
